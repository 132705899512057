import useDialog from '@blastradius/ui/hooks/use-dialog';
import loginBackground from '@customer-web-app/domains/authentication/assets/images/login-background.png';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import IncidentsCards from '@customer-web-app/domains/incidents/components/incidents-cards';
import IncidentsHeader from '@customer-web-app/domains/incidents/components/incidents-header';
import useIncidentsCounters from '@customer-web-app/domains/incidents/hooks/use-incidents-counters';
import getIncidentsMainScreen from '@customer-web-app/domains/incidents/services/get-incidents-main-screen';
import Container from '@customer-web-app/domains/shared/components/container';
import StickyHeaderWrapper from '@customer-web-app/domains/shared/components/sticky-header-wrapper';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import SwitchTenantDialog from '@customer-web-app/domains/tenants/components/switch-tenant-dialog';
import React from 'react';
import { Text } from '@blastradius/ui';

function Index() {
  const {
    user,
    canAccessMultipleTenants,
    isTenantSelected,
    redirectUserByAuthentication,
  } = useAuthentication();
  const { liveIncidentsCount, isLoadingIncidentsCounters } =
    useIncidentsCounters();
  const { openDialog } = useDialog();
  const flags = useFeatureFlags();

  React.useEffect(() => {
    if (canAccessMultipleTenants && !isTenantSelected) {
      openDialog(SwitchTenantDialog, {
        title: 'Select Tenant to Login as',
        withDeactivate: false,
        withBackground: false,
        allowClose: false,
      });
    } else {
      redirectUserByAuthentication(
        getIncidentsMainScreen({
          hasLiveIncidents: liveIncidentsCount > 0,
          enableEndpointAlerts: flags.alertsEnableEndpointAlerts as boolean,
          enableIdentityAlerts: flags.alertsEnableIdentityAlerts as boolean,
          enableNetworkAlerts: flags.alertsEnableNetworkAlerts as boolean,
          enableGenericAlerts:
            flags.alertsEnableTriageRequiredAlertStatus as boolean,
        }),
      );
    }
  }, [
    canAccessMultipleTenants,
    isTenantSelected,
    isLoadingIncidentsCounters,
    liveIncidentsCount,
  ]);

  if (canAccessMultipleTenants && !isTenantSelected) {
    return (
      <div
        className="absolute top-0 left-0 w-full h-full bg-gray-950 bg-cover bg-no-repeat bg-bottom z-50"
        style={{ backgroundImage: `url('${loginBackground.src}')` }}
      />
    );
  }

  return (
    user?.id && (
      <>
        <StickyHeaderWrapper>
          <IncidentsHeader />
        </StickyHeaderWrapper>

        <Container className="px-8 flex items-start justify-between !w-full mt-[104px]">
          <article className="w-[64rem] 2.5xl:w-[86rem] mx-auto">
            <Text
              type="body"
              size="small"
              as="h2"
              color="text-gray-500"
              className="mb-6"
            >
              Incidents
            </Text>

            <section>
              <IncidentsCards incidents={[]} loading />
            </section>
          </article>
        </Container>
      </>
    )
  );
}

export default Index;
